var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "vocabularyCard",
      staticClass: "vocabulary-card",
      class: { "component-active": _vm.isActive },
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("div", { staticClass: "flex-container" }, [
        _c("span", { staticClass: "legend" }, [
          _vm._v(_vm._s(_vm.name || _vm.$t("titleTag")))
        ]),
        _c(
          "button",
          {
            staticClass: "btn secondary",
            on: {
              click: function($event) {
                _vm.showModal = !_vm.showModal
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("preview")))]
        ),
        _c(
          "button",
          {
            staticClass: "btn secondary delete",
            on: { click: _vm.removeVocabularyComponent }
          },
          [_vm._v(_vm._s(_vm.$t("delete")))]
        )
      ]),
      _c("div", { staticClass: "flex-container" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(_vm._s(_vm.$t("titleTag")) + ":")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          attrs: { type: "text", placeholder: _vm.$t("wordPlaceHolder") },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        }),
        _c("label", { attrs: { for: "" } }, [_vm._v("Pronunciation:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pronunciation,
              expression: "pronunciation"
            }
          ],
          attrs: {
            type: "text",
            placeholder: _vm.$t("pronunciationPlaceHolder")
          },
          domProps: { value: _vm.pronunciation },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.pronunciation = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "flex-container" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(_vm._s(_vm.$t("definitionTag")) + ":")
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.definition,
              expression: "definition"
            }
          ],
          staticClass: "custom-scrollbar",
          attrs: { placeholder: _vm.$t("definitionPlaceHolder") },
          domProps: { value: _vm.definition },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.definition = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "flex-container" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(_vm._s(_vm.$t("categoryTag")) + ":")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.category,
              expression: "category"
            }
          ],
          attrs: { type: "text", placeholder: _vm.$t("categoryPlaceHolder") },
          domProps: { value: _vm.category },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.category = $event.target.value
            }
          }
        })
      ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              directives: [
                {
                  name: "closable",
                  rawName: "v-closable",
                  value: {
                    exclude: ["vocabularyCard"],
                    handler: "onCloseNotificationsBox"
                  },
                  expression:
                    "{exclude: ['vocabularyCard'],handler: 'onCloseNotificationsBox'}"
                }
              ],
              attrs: {
                "text-button": _vm.$t("done"),
                "click-handler": _vm.hideVocabularyBox,
                showCancelButton: _vm.showCancelButton,
                modalType: _vm.modalType
              }
            },
            [_c("vocabulary-box", { attrs: { vocabulary: _vm.component } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }