<template>
    <div class="vocabulary-manager builder flex-container">
        <div class="vocabulary-container flex-container custom-scrollbar">
            <transition-group name="flip-list" tag="ul">
                    <li  v-for="component in vocabularyComponents" :key="component.id" >
                        <vocabulary-card  :component="component"/>
                    </li>
            </transition-group>
            <button class="btn primary" @click="addVocabularyComponent">
              {{$t('newVocabularyWordTag')}}</button>
        </div>
        <properties-panel :save-component="saveComponent"/>
    </div>
</template>
<script>
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
import VocabularyCard from '@/components/BackOffice/VocabularyManager/VocabularyCard.vue'
export default {
    name: 'VocabularyManager', 
    mixins: [BuilderMixin], 
    components:{
        VocabularyCard
    },
    data(){
        return {
            vocabularyUpdatedMessage: this.$t('vocabularyUpdatedMessage'), 
            failedToUpdateVocabularyMessage: this.$t('failedToUpdateVocabularyMessage')
        }
    },
    computed:{
        vocabularyComponents(){
            return this.$store.state.vocabularyComponents
        },
        unitId(){
            return this.$route.params.unitId
        }, 
    },
    methods:{
        saveComponent(){
            try {
                this.vocabularyComponents.forEach((e)=>{
                    this.$store.dispatch('updateVocabularyComponent', e)
                    if(e.audio.id)
                        this.setComponentPropertyValue(e, e.audio.id, 'audioId')
                })
                this.$store.commit('addSnackBarNotification',{
                    message:  this.vocabularyUpdatedMessage
                })
            } catch (error) {
                this.$store.commit('addSnackBarNotification',{
                    message: this.failedToUpdateVocabularyMessage, 
                    dismissable: true
                })
            }
        }, 
        addVocabularyComponent(){
            this.$store.dispatch('addVocabularyComponent', this.unitId)
        },
        setComponentPropertyValue(component, value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {'component': component, 'propertyName': propertyName, 'value': value})
        }, 
    }, 
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.dispatch('loadVocabulary', this.unitId)
        this.$store.commit('updateShowPreviewModeOptions', false)
    },
    beforeDestroy() {
        this.$store.commit('updateShowPreviewModeOptions', true)
    }

}
</script>
<style lang="less" scoped>
.vocabulary-manager{
    width: 1024px; 
    max-width: 1024px; 
    margin: 0 auto;
    height: calc(100vh - 104px);
    margin-top: 104px;
    min-height: 300px;
    .vocabulary-container{
        margin: 0 auto;
        margin-left: 34px;
        flex-direction: column;
        padding-bottom: 19px;
        min-width: 704px;
        max-height: calc(100vh - 104px);
        overflow-y: auto;
        padding: 10px;
        .btn{
            width: 224px;
            margin: 20px auto;
            min-height: 32px;
        }
    }
}
</style>

