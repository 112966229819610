<template>
    <div class="vocabulary-card" @click="updateCurrentComponent" ref="vocabularyCard" :class="{'component-active': isActive}">
        <div class="flex-container">
            <span class="legend">{{name || $t('titleTag')}}</span> 
            <button class="btn secondary" @click="showModal = !showModal">{{$t('preview')}}</button>
            <button class="btn secondary delete" @click="removeVocabularyComponent">{{$t('delete')}}</button>
        </div>
        <div class="flex-container">
            <label for="">{{$t('titleTag')}}:</label>
            <input type="text" v-model="name" :placeholder="$t('wordPlaceHolder')">
            <label for="">Pronunciation:</label>
            <input type="text" v-model="pronunciation" :placeholder="$t('pronunciationPlaceHolder')">
        </div>
        <div class="flex-container">
            <label for="">{{$t('definitionTag')}}:</label>
            <textarea v-model="definition" class="custom-scrollbar" :placeholder="$t('definitionPlaceHolder')"></textarea>
        </div>
        <div class="flex-container">
            <label for="">{{$t('categoryTag')}}:</label>
            <input type="text" v-model="category" :placeholder="$t('categoryPlaceHolder')">
        </div>
         <modal v-if="showModal" :text-button="$t('done')" 
            :click-handler="hideVocabularyBox" 
            :showCancelButton="showCancelButton"
             v-closable="{exclude: ['vocabularyCard'],handler: 'onCloseNotificationsBox'}" 
            :modalType="modalType"> <vocabulary-box :vocabulary="component" /></modal>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import VocabularyBox from '@/components/Lesson/VocabularyBox.vue'
import Modal from '@/common/Modal.vue'
export default {
    name: 'VocaularyCard',
    mixins:[BackOfficeComponentMixin], 
    components:{
        Modal, 
        VocabularyBox
    },
    data(){
        return{
            modalType: 'vocabularyModal',
            showModal: false,
            showCancelButton: false
        }
    },
    methods:{
        removeVocabularyComponent(){
            this.$store.dispatch('removeVocabularyComponent', this.component)
                .then(()=>{
                    this.$store.commit('updateCurrentBackOfficeComponent', undefined)
                })
        }, 
        previewVocabulary(){

        }, 
        hideVocabularyBox(){
            this.showModal = false
        }
    }, 
    computed:{
        name:{
            get(){
                return this.component.name[this.locale]
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'name',this.locale)
            }
        },
        pronunciation:{
            get(){
                return this.component.pronunciation[this.locale]
            },
            set(value){
                this.setComponentPropertyValue(value, "pronunciation", this.locale)
            }
        },
        definition:{
            get(){
                return this.component.definition[this.locale]
            },
            set(value){
                this.setComponentPropertyValue(value, "definition",this.locale)
            }
        },
        category:{
            get(){
                return this.component.category[this.locale]
            },
            set(value){
                this.setComponentPropertyValue(value, "category",this.locale)
            }
        }
    }
}
</script>


