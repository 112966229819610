var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vocabulary-manager builder flex-container" },
    [
      _c(
        "div",
        { staticClass: "vocabulary-container flex-container custom-scrollbar" },
        [
          _c(
            "transition-group",
            { attrs: { name: "flip-list", tag: "ul" } },
            _vm._l(_vm.vocabularyComponents, function(component) {
              return _c(
                "li",
                { key: component.id },
                [_c("vocabulary-card", { attrs: { component: component } })],
                1
              )
            })
          ),
          _c(
            "button",
            {
              staticClass: "btn primary",
              on: { click: _vm.addVocabularyComponent }
            },
            [_vm._v("\n          " + _vm._s(_vm.$t("newVocabularyWordTag")))]
          )
        ],
        1
      ),
      _c("properties-panel", { attrs: { "save-component": _vm.saveComponent } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }